.CodeMirror {
  color: #000;
  direction: ltr;
  height: 300px;
  font-family: monospace;
}

.CodeMirror-lines {
  padding: 4px 0;
}

.CodeMirror pre.CodeMirror-line, .CodeMirror pre.CodeMirror-line-like {
  padding: 0 4px;
}

.CodeMirror-gutter-filler, .CodeMirror-scrollbar-filler {
  background-color: #fff;
}

.CodeMirror-gutters {
  white-space: nowrap;
  background-color: #f7f7f7;
  border-right: 1px solid #ddd;
}

.CodeMirror-linenumber {
  text-align: right;
  color: #999;
  white-space: nowrap;
  min-width: 20px;
  padding: 0 3px 0 5px;
}

.CodeMirror-guttermarker {
  color: #000;
}

.CodeMirror-guttermarker-subtle {
  color: #999;
}

.CodeMirror-cursor {
  border-left: 1px solid #000;
  border-right: none;
  width: 0;
}

.CodeMirror div.CodeMirror-secondarycursor {
  border-left: 1px solid silver;
}

.cm-fat-cursor .CodeMirror-cursor {
  background: #7e7;
  width: auto;
  border: 0 !important;
}

.cm-fat-cursor div.CodeMirror-cursors {
  z-index: 1;
}

.cm-fat-cursor .CodeMirror-line::selection, .cm-fat-cursor .CodeMirror-line > span::selection, .cm-fat-cursor .CodeMirror-line > span > span::selection {
  background: none;
}

.cm-fat-cursor .CodeMirror-line::-moz-selection {
  background: none;
}

.cm-fat-cursor .CodeMirror-line > span::-moz-selection {
  background: none;
}

.cm-fat-cursor .CodeMirror-line > span > span::-moz-selection {
  background: none;
}

.cm-fat-cursor {
  caret-color: #0000;
}

@keyframes blink {
  50% {
    background-color: #0000;
  }
}

.cm-tab {
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  display: inline-block;
}

.CodeMirror-rulers {
  position: absolute;
  inset: -50px 0 0;
  overflow: hidden;
}

.CodeMirror-ruler {
  border-left: 1px solid #ccc;
  position: absolute;
  top: 0;
  bottom: 0;
}

.cm-s-default .cm-header {
  color: #00f;
}

.cm-s-default .cm-quote {
  color: #090;
}

.cm-negative {
  color: #d44;
}

.cm-positive {
  color: #292;
}

.cm-header, .cm-strong {
  font-weight: 700;
}

.cm-em {
  font-style: italic;
}

.cm-link {
  text-decoration: underline;
}

.cm-strikethrough {
  text-decoration: line-through;
}

.cm-s-default .cm-keyword {
  color: #708;
}

.cm-s-default .cm-atom {
  color: #219;
}

.cm-s-default .cm-number {
  color: #164;
}

.cm-s-default .cm-def {
  color: #00f;
}

.cm-s-default .cm-variable-2 {
  color: #05a;
}

.cm-s-default .cm-type, .cm-s-default .cm-variable-3 {
  color: #085;
}

.cm-s-default .cm-comment {
  color: #a50;
}

.cm-s-default .cm-string {
  color: #a11;
}

.cm-s-default .cm-string-2 {
  color: #f50;
}

.cm-s-default .cm-meta, .cm-s-default .cm-qualifier {
  color: #555;
}

.cm-s-default .cm-builtin {
  color: #30a;
}

.cm-s-default .cm-bracket {
  color: #997;
}

.cm-s-default .cm-tag {
  color: #170;
}

.cm-s-default .cm-attribute {
  color: #00c;
}

.cm-s-default .cm-hr {
  color: #999;
}

.cm-s-default .cm-link {
  color: #00c;
}

.cm-s-default .cm-error, .cm-invalidchar {
  color: red;
}

.CodeMirror-composing {
  border-bottom: 2px solid;
}

div.CodeMirror span.CodeMirror-matchingbracket {
  color: #0b0;
}

div.CodeMirror span.CodeMirror-nonmatchingbracket {
  color: #a22;
}

.CodeMirror-matchingtag {
  background: #ff96004d;
}

.CodeMirror-activeline-background {
  background: #e8f2ff;
}

.CodeMirror {
  background: #fff;
  position: relative;
  overflow: hidden;
}

.CodeMirror-scroll {
  z-index: 0;
  outline: 0;
  height: 100%;
  margin-bottom: -50px;
  margin-right: -50px;
  padding-bottom: 50px;
  position: relative;
  overflow: scroll !important;
}

.CodeMirror-sizer {
  border-right: 50px solid #0000;
  position: relative;
}

.CodeMirror-gutter-filler, .CodeMirror-hscrollbar, .CodeMirror-scrollbar-filler, .CodeMirror-vscrollbar {
  z-index: 6;
  outline: 0;
  display: none;
  position: absolute;
}

.CodeMirror-vscrollbar {
  top: 0;
  right: 0;
  overflow: hidden scroll;
}

.CodeMirror-hscrollbar {
  bottom: 0;
  left: 0;
  overflow: scroll hidden;
}

.CodeMirror-scrollbar-filler {
  bottom: 0;
  right: 0;
}

.CodeMirror-gutter-filler {
  bottom: 0;
  left: 0;
}

.CodeMirror-gutters {
  z-index: 3;
  min-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.CodeMirror-gutter {
  white-space: normal;
  vertical-align: top;
  height: 100%;
  margin-bottom: -50px;
  display: inline-block;
}

.CodeMirror-gutter-wrapper {
  z-index: 4;
  position: absolute;
  background: none !important;
  border: none !important;
}

.CodeMirror-gutter-background {
  z-index: 4;
  position: absolute;
  top: 0;
  bottom: 0;
}

.CodeMirror-gutter-elt {
  cursor: default;
  z-index: 4;
  position: absolute;
}

.CodeMirror-gutter-wrapper ::selection {
  background-color: #0000;
}

.CodeMirror-lines {
  cursor: text;
  min-height: 1px;
}

.CodeMirror pre.CodeMirror-line, .CodeMirror pre.CodeMirror-line-like {
  font-family: inherit;
  font-size: inherit;
  white-space: pre;
  word-wrap: normal;
  line-height: inherit;
  color: inherit;
  z-index: 2;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-variant-ligatures: contextual;
  font-variant-ligatures: contextual;
  background: none;
  border-width: 0;
  border-radius: 0;
  margin: 0;
  position: relative;
  overflow: visible;
}

.CodeMirror-wrap pre.CodeMirror-line, .CodeMirror-wrap pre.CodeMirror-line-like {
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: normal;
}

.CodeMirror-linebackground {
  z-index: 0;
  position: absolute;
  inset: 0;
}

.CodeMirror-linewidget {
  z-index: 2;
  padding: .1px;
  position: relative;
}

.CodeMirror-code {
  outline: 0;
}

.CodeMirror-gutter, .CodeMirror-gutters, .CodeMirror-linenumber, .CodeMirror-scroll, .CodeMirror-sizer {
  box-sizing: content-box;
}

.CodeMirror-measure {
  visibility: hidden;
  width: 100%;
  height: 0;
  position: absolute;
  overflow: hidden;
}

.CodeMirror-cursor {
  pointer-events: none;
  position: absolute;
}

.CodeMirror-measure pre {
  position: static;
}

div.CodeMirror-cursors {
  visibility: hidden;
  z-index: 3;
  position: relative;
}

div.CodeMirror-dragcursors, .CodeMirror-focused div.CodeMirror-cursors {
  visibility: visible;
}

.CodeMirror-selected {
  background: #d9d9d9;
}

.CodeMirror-focused .CodeMirror-selected {
  background: #d7d4f0;
}

.CodeMirror-crosshair {
  cursor: crosshair;
}

.CodeMirror-line::selection, .CodeMirror-line > span::selection, .CodeMirror-line > span > span::selection {
  background: #d7d4f0;
}

.CodeMirror-line::-moz-selection {
  background: #d7d4f0;
}

.CodeMirror-line > span::-moz-selection {
  background: #d7d4f0;
}

.CodeMirror-line > span > span::-moz-selection {
  background: #d7d4f0;
}

.cm-searching {
  background-color: #ff06;
}

.cm-force-border {
  padding-right: .1px;
}

@media print {
  .CodeMirror div.CodeMirror-cursors {
    visibility: hidden;
  }
}

.cm-tab-wrap-hack:after {
  content: "";
}

span.CodeMirror-selectedtext {
  background: none;
}

.EasyMDEContainer {
  display: block;
}

.CodeMirror-rtl pre {
  direction: rtl;
}

.EasyMDEContainer.sided--no-fullscreen {
  flex-flow: wrap;
  display: flex;
}

.EasyMDEContainer .CodeMirror {
  box-sizing: border-box;
  height: auto;
  font: inherit;
  z-index: 0;
  word-wrap: break-word;
  border: 1px solid #ced4da;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 10px;
}

.EasyMDEContainer .CodeMirror-scroll {
  cursor: text;
}

.EasyMDEContainer .CodeMirror-fullscreen {
  z-index: 8;
  background: #fff;
  height: auto;
  inset: 50px 0 0;
  border-right: none !important;
  border-bottom-right-radius: 0 !important;
  position: fixed !important;
}

.EasyMDEContainer .CodeMirror-sided {
  width: 50% !important;
}

.EasyMDEContainer.sided--no-fullscreen .CodeMirror-sided {
  border-bottom-right-radius: 0;
  flex: auto;
  position: relative;
  border-right: none !important;
}

.EasyMDEContainer .CodeMirror-placeholder {
  opacity: .5;
}

.EasyMDEContainer .CodeMirror-focused .CodeMirror-selected {
  background: #d9d9d9;
}

.editor-toolbar {
  -webkit-user-select: none;
  user-select: none;
  -o-user-select: none;
  border-top: 1px solid #ced4da;
  border-left: 1px solid #ced4da;
  border-right: 1px solid #ced4da;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 9px 10px;
  position: relative;
}

.editor-toolbar.fullscreen {
  box-sizing: border-box;
  opacity: 1;
  z-index: 9;
  background: #fff;
  border: 0;
  width: 100%;
  height: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  position: fixed;
  top: 0;
  left: 0;
}

.editor-toolbar.fullscreen:before {
  background: -o-linear-gradient(left, #fff 0, #fff0 100%);
  background: -ms-linear-gradient(left, #fff 0, #fff0 100%);
  background: -moz-linear-gradient(left, #fff 0, #fff0 100%);
  background: -o-linear-gradient(left, #fff 0, #fff0 100%);
  background: linear-gradient(to right, #fff 0, #fff0 100%);
  width: 20px;
  height: 50px;
  margin: 0;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
}

.editor-toolbar.fullscreen:after {
  background: -o-linear-gradient(left, #fff0 0, #fff 100%);
  background: -ms-linear-gradient(left, #fff0 0, #fff 100%);
  background: -moz-linear-gradient(left, #fff0 0, #fff 100%);
  background: -o-linear-gradient(left, #fff0 0, #fff 100%);
  background: linear-gradient(to right, #fff0 0, #fff 100%);
  width: 20px;
  height: 50px;
  margin: 0;
  padding: 0;
  position: fixed;
  top: 0;
  right: 0;
}

.EasyMDEContainer.sided--no-fullscreen .editor-toolbar {
  width: 100%;
}

.editor-toolbar .easymde-dropdown, .editor-toolbar button {
  text-align: center;
  cursor: pointer;
  background: none;
  border: 1px solid #0000;
  border-radius: 3px;
  height: 30px;
  margin: 0;
  padding: 0;
  display: inline-block;
  text-decoration: none !important;
}

.editor-toolbar button {
  white-space: nowrap;
  min-width: 30px;
  padding: 0 6px;
  font-weight: 700;
}

.editor-toolbar button.active, .editor-toolbar button:hover {
  background: #fcfcfc;
  border-color: #95a5a6;
}

.editor-toolbar i.separator {
  color: #0000;
  text-indent: -10px;
  border-left: 1px solid #d9d9d9;
  border-right: 1px solid #fff;
  width: 0;
  margin: 0 6px;
  display: inline-block;
}

.editor-toolbar button:after {
  vertical-align: text-bottom;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 65%;
  position: relative;
  top: 2px;
}

.editor-toolbar button.heading-1:after {
  content: "1";
}

.editor-toolbar button.heading-2:after {
  content: "2";
}

.editor-toolbar button.heading-3:after {
  content: "3";
}

.editor-toolbar button.heading-bigger:after {
  content: "▲";
}

.editor-toolbar button.heading-smaller:after {
  content: "▼";
}

.editor-toolbar.disabled-for-preview button:not(.no-disable) {
  opacity: .6;
  pointer-events: none;
}

@media only screen and (width <= 700px) {
  .editor-toolbar i.no-mobile {
    display: none;
  }
}

.editor-statusbar {
  color: #959694;
  text-align: right;
  padding: 8px 10px;
  font-size: 12px;
}

.EasyMDEContainer.sided--no-fullscreen .editor-statusbar {
  width: 100%;
}

.editor-statusbar span {
  min-width: 4em;
  margin-left: 1em;
  display: inline-block;
}

.editor-statusbar .lines:before {
  content: "lines: ";
}

.editor-statusbar .words:before {
  content: "words: ";
}

.editor-statusbar .characters:before {
  content: "characters: ";
}

.editor-preview-full {
  z-index: 7;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  overflow: auto;
}

.editor-preview-side {
  z-index: 9;
  box-sizing: border-box;
  word-wrap: break-word;
  border: 1px solid #ddd;
  width: 50%;
  display: none;
  position: fixed;
  top: 50px;
  bottom: 0;
  right: 0;
  overflow: auto;
}

.editor-preview-active-side {
  display: block;
}

.EasyMDEContainer.sided--no-fullscreen .editor-preview-active-side {
  flex: auto;
  height: auto;
  position: static;
}

.editor-preview-active {
  display: block;
}

.editor-preview {
  background: #fafafa;
  padding: 10px;
}

.editor-preview > p {
  margin-top: 0;
}

.editor-preview pre {
  background: #eee;
  margin-bottom: 10px;
}

.editor-preview table td, .editor-preview table th {
  border: 1px solid #ddd;
  padding: 5px;
}

.cm-s-easymde .cm-tag {
  color: #63a35c;
}

.cm-s-easymde .cm-attribute {
  color: #795da3;
}

.cm-s-easymde .cm-string {
  color: #183691;
}

.cm-s-easymde .cm-header-1 {
  font-size: calc(1.375rem + 1.5vw);
}

.cm-s-easymde .cm-header-2 {
  font-size: calc(1.325rem + .9vw);
}

.cm-s-easymde .cm-header-3 {
  font-size: calc(1.3rem + .6vw);
}

.cm-s-easymde .cm-header-4 {
  font-size: calc(1.275rem + .3vw);
}

.cm-s-easymde .cm-header-5 {
  font-size: 1.25rem;
}

.cm-s-easymde .cm-header-6 {
  font-size: 1rem;
}

.cm-s-easymde .cm-header-1, .cm-s-easymde .cm-header-2, .cm-s-easymde .cm-header-3, .cm-s-easymde .cm-header-4, .cm-s-easymde .cm-header-5, .cm-s-easymde .cm-header-6 {
  margin-bottom: .5rem;
  line-height: 1.2;
}

.cm-s-easymde .cm-comment {
  background: #0000000d;
  border-radius: 2px;
}

.cm-s-easymde .cm-link {
  color: #7f8c8d;
}

.cm-s-easymde .cm-url {
  color: #aab2b3;
}

.cm-s-easymde .cm-quote {
  color: #7f8c8d;
  font-style: italic;
}

.editor-toolbar .easymde-dropdown {
  background: linear-gradient(to bottom right, #fff 0 84%, #333 50% 100%);
  border: 1px solid #fff;
  border-radius: 0;
  position: relative;
}

.editor-toolbar .easymde-dropdown:hover {
  background: linear-gradient(to bottom right, #fff 0 84%, #333 50% 100%);
}

.easymde-dropdown-content {
  visibility: hidden;
  z-index: 2;
  background-color: #f9f9f9;
  padding: 8px;
  display: block;
  position: absolute;
  top: 30px;
  box-shadow: 0 8px 16px #0003;
}

.easymde-dropdown:active .easymde-dropdown-content, .easymde-dropdown:focus .easymde-dropdown-content, .easymde-dropdown:focus-within .easymde-dropdown-content {
  visibility: visible;
}

.easymde-dropdown-content button {
  display: block;
}

span[data-img-src]:after {
  content: "";
  background-image: var(--bg-image);
  max-width: 100%;
  height: 0;
  max-height: 100%;
  padding-top: var(--height);
  width: var(--width);
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
}

.CodeMirror .cm-spell-error:not(.cm-url):not(.cm-comment):not(.cm-tag):not(.cm-word) {
  background: #ff000026;
}
/*# sourceMappingURL=app.9aaffbb4.css.map */
